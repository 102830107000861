

















import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class TooltipButton extends Vue {
  @Prop() icono_boton!: string;
  @Prop({ default: "primary" }) color_boton!: string;
  @Prop({ default: "" }) text_toolstrip!: string;
  @Prop({ default: false }) loading!: boolean;
}
